<template>
	<!-- <el-dialog :title="$t('i18nn_f7e99b8c203e2b07')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px"
		top="10px"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_b2d95e887d5425b7')" append-to-body :visible.sync="dialogFile" :direction="'rtl'" size="1000px">
		<div class="">
			<!--本页切换列表-->
			<div>
				<div class="tableCon" v-loading="loading_load" element-loading-text="加载中...">
					<div class="tableConTable">
						<!-- <div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('i18nn_ca5a01a5adf20fe7')}}</el-button>{{$t('i18nn_92f2ebbe14794f38')}}</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">


								<el-button type="success" circle icon="el-icon-refresh" size="small"
									@click="initData()"></el-button>
							</el-col>
						</el-row>
					</div> -->

						<!-- <div>
						<div class="filterCon" style="">
						</div>
					</div> -->

						<!-- <div>
							<el-card shadow="never" style="margin-bottom: 10px;">
								<el-descriptions :title="''">
									<el-descriptions-item :label="$t('i18nn_37181c74559db182')">{{tableData.orderNumber}}</el-descriptions-item>
									
									<el-descriptions-item :label="$t('i18nn_148c3451c7861e8f')">{{tableData.batchNo}}</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_ce764b74be1b9a90')">{{tableData.workNo}}</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_83b1b50eb00a9fb6')">{{tableData.thirdOrderNumber}}</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_18d7892498938435')">{{tableData.cusName}}</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_6cdece641436d7ab')">
										{{ tableData.exprStatusName }}
									</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_43a3586339251494')">{{tableData.commitDate}}</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_d99d790ec4383bfb')">{{tableData.exprStatusTime}}</el-descriptions-item>
								</el-descriptions>
							</el-card> -->

							<el-card shadow="never" style="margin-bottom: 10px;">
								<div slot="header" class="">
									<span>{{$t('i18nn_994551e2ae3176ca')}}</span>
								</div>
								<el-table border :data="row.planList" size="small"
									style="width: 100%;">
									<el-table-column type="index" fixed="left" width="50" align="center"
										:label="$t('Storage.tableColumn.no')"></el-table-column>
								
									<el-table-column prop="inWhCode" :label="$t('i18nn_35ec768075eb8f4e')">
									</el-table-column>
									
									<el-table-column prop="relationNo" :label="$t('i18nn_3d3bc466f13154d4')">
									</el-table-column>
								
									<el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
										<template slot-scope="scope">
											<div>
												<!-- <el-input size="small" v-model="scope.row.ctnMark">
											</el-input> -->
												{{scope.row.ctnMark}}
											</div>
										</template>
									</el-table-column>
								
									<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')" width="">
										<!-- <template slot-scope="scope">
											<div>
												<el-input-number size="small" v-model="scope.row.quantity" controls-position="right">
												</el-input-number>
											</div>
										</template> -->
									</el-table-column>
								
									<el-table-column prop="fbaNo" :label="'FBA编号'" width="">
										<!-- <template slot-scope="scope">
											<div>
												<el-input size="small" v-model="scope.row.fbaNo">
												</el-input>
											</div>
										</template> -->
									</el-table-column>
								
									<el-table-column prop="fbaPre" :label="'FBA PRE#'" width="">
										<!-- <template slot-scope="scope">
											<div>
												<el-input size="small" v-model="scope.row.fbaPre">
												</el-input>
											</div>
										</template> -->
									</el-table-column>
									
									<!-- <el-table-column prop="trRecordId" :label="'入库ID'">
									</el-table-column> -->
									
								</el-table>
							</el-card>

							
							<el-card shadow="never" style="">
								<div slot="header" class="">
									<span>{{$t('i18nn_f5d43732e3f6cf4d')}}</span>
								</div>
								<ul>
									<li v-for="(item2,index2) in row.attachments" :key="index2">
										<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
											:src="item2.url" :preview-src-list="row.attachments.map(itemPre=> { return itemPre.url})">
											<div slot="error" class="image-slot">
												<i class="el-icon-document"></i>
											</div>
										</el-image>
										<a :href="item2.url" :title="item2.url" target="_blank">{{ item2.fileName }}</a>
								
										<!-- <el-button @click="delFile($event,index, index2)" type="text" size="mini" icon="el-icon-delete"
											style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button> -->
									</li>
								</ul>
							</el-card>
						</div>

						
					</div>
					<!-- <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div> -->
				</div>
			</div>


		</div>
		<!-- <div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{ $t('i18nn_4e9fc68608c60999') }}</el-button>
		</div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	export default {
		// name: 'HomeConfFrame',
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
		},
		components: {

		},
		data() {
			return {
				dialogFile: false,
				// multipleSelection: [],
				// expands:[],

				loading_load: false,

				// loading_det_load: false,

				// loading_count: false,
				// countData: {},

				//表格数据
				//loading,表格数据
				loading: false,
				//表格数据
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),

				selectOption: {
					wh_no: [],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// workNo: '',
					// exprStatus: '',
					orderNumber: '',
					// orderNumberList: [],
					// orderNumberListStr: '',
					// // cusNo: '',
					// userId: '',
					// commitDate: '',
					// commitDateArr: [],
					// isLabelUrl: 1,
					// carrier: '',
					// keyword: '',
					// senderAddr: '',
					// returnAddr: '',
					// batchNo: ''
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},

		activated() {
			console.log('WhDropShipping activated');

			// this.clearFilter();
			// if (this.$route.query && this.$route.query.orderNumber) {
			// 	this.filterData.orderNumber = this.$route.query.orderNumber;
			// }

			// this.initData();
		},
		//创建时
		created() {
			console.log('WhDropShipping created');
			// this.clearFilter();
			// if (this.$route.query && this.$route.query.state) {
			// 	this.filterData.exprStatus = this.$route.query.state;
			// }
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoListData();
		},
		methods: {
			goBack() {
				// console.log('window.history.length',window.history.length);
				// if (window.history.length > 2) {
				// 	this.$router.go(-1);
				// } else {
				// 	this.$router.push({
				// 		name: 'WhDropShippingDashboard'
				// 	});
				// }
			},

			initData() {
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				// this.getPageData();
				this.tableData = this.row;

				// this.$nextTick(() => {
				// 	this.$refs.multipleTable2.doLayout();
				// });
				// this.getDicData();
			},
			//分页的筛选项数据
			// pageFilterData() {
			// 	// let commitDateArr = this.filterData.commitDateArr;
			// 	// let startCommitDate = '';
			// 	// let endCommitDate = '';
			// 	// if (commitDateArr && commitDateArr.length == 2) {
			// 	// 	startCommitDate = commitDateArr[0];
			// 	// 	endCommitDate = commitDateArr[1];
			// 	// }
			// 	// let orderNumberList = [];

			// 	// if (this.filterData.orderNumberListStr) {
			// 	// 	orderNumberList = this.filterData.orderNumberList;
			// 	// } else if (this.filterData.orderNumber) {
			// 	// 	orderNumberList = [this.filterData.orderNumber];
			// 	// }

			// 	return {
			// 		// workNo: this.filterData.workNo ? this.filterData.workNo : null,
			// 		// batchNo: this.filterData.batchNo ? this.filterData.batchNo : null,
			// 		// exprStatus: this.filterData.exprStatus ? this.filterData.exprStatus : null,
			// 		orderNumber: this.filterData.orderNumber ? this.filterData.orderNumber : null,
			// 		// orderNumberList: orderNumberList.length > 0 ? orderNumberList : null,
			// 		// // cusNo: this.filterData.cusNo ? this.filterData.cusNo : null,
			// 		// userId: this.filterData.userId ? this.filterData.userId : null,
			// 		// // commitDate: this.filterData.commitDate ? this.filterData.commitDate : null,

			// 		// startCommitDate: startCommitDate ? startCommitDate : null,
			// 		// endCommitDate: endCommitDate ? endCommitDate : null,

			// 		// keyword: this.filterData.keyword ? this.filterData.keyword : null,
			// 		// carrier: this.filterData.carrier ? this.filterData.carrier : null,
			// 		// isLabelUrl: this.filterData.isLabelUrl ? this.filterData.isLabelUrl : null,

			// 		// senderAddr: this.filterData.senderAddr ? this.filterData.senderAddr : null,
			// 		// returnAddr: this.filterData.returnAddr ? this.filterData.returnAddr : null
			// 		// // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
			// 	};
			// },

			// //请求分页数据
			// getPageData() {
			// 	// let _this = this;

			// 	let filterData = Object.assign({
			// 			offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
			// 			limit: this.pagination.page_size //当前页显示数目
			// 		},
			// 		this.pageFilterData()
			// 	);

			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhDropShippingPageList, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.rows && data.rows[0]) {
			// 				//表格显示数据
			// 				this.tableData = data.rows[0];
			// 				//当前数据总条数
			// 				// this.pagination.total = parseInt(data.total);
			// 				//当前页数
			// 				// this.pagination.current_page = parseInt(data.current);
			// 				//当前页条数
			// 				// this.pagination.page_size = parseInt(data.size);
			// 				// this.$message.success('商家账单列表，请求成功');
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('列表数据，请求失败！');
			// 			this.loading_load = false;
			// 		});
			// },

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},


			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback(data);
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_carrier_code', 'wh_signature_service'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				this.selectOption.wh_carrier_code = data.data['wh_carrier_code'];
			// 				this.selectOption.wh_signature_service = data.data['wh_signature_service'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// },

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
